export default [
  {
    key: 'code',
    label: 'field.code',
    type: 'text',
    operator: '%',
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    type: 'text',
    operator: '%',
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    type: 'text',
    operator: '%',
  },
  {
    key: 'isUnlimited',
    label: 'field.usage',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'single-selection',
    translatable: true,
    clearable: true,
    cast: 'boolean',
    options: [
      { text: 'field.unlimited', value: '1' },
      { text: 'field.limited', value: '0' },
    ],
  },
  {
    key: 'isEnable',
    label: 'field.status',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'single-selection',
    translatable: true,
    clearable: true,
    cast: 'boolean',
    options: [
      { text: 'field.active', value: '1' },
      { text: 'field.inactive', value: '0' },
    ],
  },
]
